body {
  font-family: "Open Sans", sans-serif;
}

.stage-wrapper {
  display: flex;
  margin: 0 10px 10px 0;
}

.stage-wrapper:hover div:not(.stage-active) {
  background-color: #00cccc;
}

.stage-wrapper:hover div:not(.stage-active):after {
  border-left-color: #00cccc;
}

.stage-item {
  background-color: #bababa;
  border-color: #bababa;
  color: #fff;
  flex-basis: 100%;
  font-size: 16px;
  padding: 4px 0 4px 22px;
  position: relative;
}

.stage-item:before, .stage-item:after {
  border: solid transparent;
  content: " ";
  position: absolute;
  top: CALC(50% - 16px);;
  z-index: 2;
  left: CALC(100% - 1px);
}

.stage-item:after {
  border-left-color: inherit;
  border-width: 16px;
}

.stage-item:before {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 18px;
  margin-top: -2px;
}

.stage-item.stage-active {
  background-color: #006666;
}

.stage-item.stage-active:after {
  border-left-color: #006666;
}

.stage-item:hover {
  background-color: #00cccc;
}

.stage-item:hover:after {
  border-left-color: #00cccc;
}

.stage-item:hover ~ div {
  background-color: #00cccc;
}

.stage-item:hover ~ div:after {
  border-left-color: #00cccc;
}

.stage-item:hover ~ div:not(.stage-active) {
  background-color: #bababa;
}

.stage-item:hover ~ div:not(.stage-active):after {
  border-left-color: #bababa;
}

/************************************* Main ***************/
.main .container-fluid {
  padding: 0 15px;
}

/************************************* Cards ***************/
.card .card-header {
  padding: .5rem;
  font-weight: bold;
}

/************************************* Tables ***************/
.overflow-row {
  overflow-x: auto;
}

/************************************* Select2 ***************/
.select2-no-borders {
  border: none !important;
}

.select2-no-background {
  background-color: inherit !important;
}

.select2-no-search .select2-search {
  display: none;
}

/************************************* Modals ***************/
.modal-footer .btn {
  width: 100%;
}

@media (max-width: 576px) {
  .modal-footer {
    display: block;
  }
  .modal-footer .btn {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.modal-body {
  overflow-y: auto;
}

/************************************** Image Galleries *********/
.pswp__img {
  height: auto !important;
}

#thumbnails figure > a > img {
  max-width: 290px;
  max-height: 250px;
}

.thumbnail-icon {
  font-size: 85px;
  margin-bottom: 10px;
}

.thumbnail-icon-link {
  height: 250px;
  width: 250px;
}

@media (max-width: 1366px) {
  #thumbnails figure > a > img {
    max-width: 250px;
    max-height: 175px;
  }
  .thumbnail-icon {
    font-size: 60px;
    margin-bottom: 7px;
  }
  .thumbnail-icon-link {
    height: 175px;
    width: 175px;
  }
}

@media (max-width: 450px) {
  #thumbnails figure > a > img {
    max-width: 200px;
    max-height: 100px;
  }
  .thumbnail-icon {
    font-size: 35px;
    margin-bottom: 7px;
  }
  .thumbnail-icon-link {
    height: 100px;
    width: 100px;
  }
}

.modal-mode #thumbnails figure > a > img {
  max-width: 100%;
  width: 100%;
  max-height: inherit;
}

.modal-mode .card-body {
  max-height: inherit;
}

.modal-mode figure {
  width: 100%;
}

/************************************* Floating Labels ***************/
.ffl-wrapper {
  position: relative;
  display: block;
  padding-top: 1rem;
}

.ffl-wrapper .ffl-label {
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  position: absolute;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  top: 1.25rem;
}

.ffl-wrapper.ffl-floated .ffl-label {
  top: 0;
}

.ffl-wrapper input,
.ffl-wrapper textarea {
  appearance: none;
}

.dataTable th > input {
  appearance: none;
  transition: border-bottom 100ms ease;
  display: block;
  width: 100%;
  padding: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border-style: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  height: 1.875rem;
}

.dataTable th > input:hover {
  border-bottom-width: 1px;
  border-bottom-color: #909090;
}

.dataTable th > input:focus {
  border-bottom-width: 1px;
  border-bottom-color: #0289f3;
}

.dataTable th > input {
  appearance: none;
  transition: border-bottom 100ms ease;
  display: block;
  width: 100%;
  padding: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border-style: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  height: 1.875rem;
}

.dataTable th > input:hover {
  border-bottom-width: 1px;
  border-bottom-color: #909090;
}

.dataTable th > input:focus {
  border-bottom-width: 1px;
  border-bottom-color: #0289f3;
}

.ffl-wrapper {
  position: relative;
  display: block;
  padding-top: 1rem;
}

.ffl-wrapper .ffl-label {
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  position: absolute;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  top: 1.25rem;
}

.ffl-wrapper.ffl-floated .ffl-label {
  top: 0;
}

.ffl-wrapper input,
.ffl-wrapper textarea {
  appearance: none;
  transition: border-bottom 100ms ease;
  display: block;
  width: 100%;
  padding: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border-style: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  min-height: 1.875rem;
}

.ffl-wrapper input:hover,
.ffl-wrapper textarea:hover {
  border-bottom-width: 1px;
  border-bottom-color: #909090;
}

.ffl-wrapper input:focus,
.ffl-wrapper textarea:focus {
  border-bottom-width: 1px;
  border-bottom-color: #0289f3;
}

.ffl-wrapper input,
.ffl-wrapper textarea,
.ffl-wrapper select,
.ffl-wrapper .select2 .selection .select2-selection {
  transition: border-bottom 100ms ease;
  display: block;
  width: 100%;
  padding: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border-style: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  min-height: 1.875rem;
}

.ffl-wrapper input:hover,
.ffl-wrapper textarea:hover,
.ffl-wrapper select:hover,
.ffl-wrapper .select2 .selection .select2-selection:hover {
  border-bottom-width: 1px;
  border-bottom-color: #909090;
}

.ffl-wrapper input:focus,
.ffl-wrapper textarea:focus,
.ffl-wrapper select:focus,
.ffl-wrapper .select2 .selection .select2-selection:focus {
  border-bottom-width: 1px;
  border-bottom-color: #0289f3;
}

.ffl-wrapper .select2-hidden-accessible {
  display: none;
}

.ffl-label {
  color: #909090;
  line-height: 1.2;
}

.ffl-floated .ffl-label {
  color: #0289f3;
  font-size: 0.75rem;
  overflow: visible;
}

.select2 .selection .select2-selection .select2-selection__rendered {
  padding-left: 0;
}

textarea {
  resize: none;
  min-height: 1.875rem;
}

.note-editor.note-frame .note-editing-area .note-editable b {
  font-weight: bold !important;
}

/************************************* Business Rules Highlighting ***************/
.green-highlight {
  color: #006100 !important;
  background-color: #C6EFCE !important;
}

.yellow-highlight {
  color: #9C6500 !important;
  background-color: #FFEB9C !important;
}

.red-highlight {
  color: #9C0006 !important;
  background-color: #FFC7CE !important;
}

/************************************* Main Search Container ***************/
#main-search-container {
  background-color: white;
}

#main-search-container input {
  background-color: white;
  padding: 0 4px;
  margin-top: 0;
}

/************************************* Rotated Table Headers***************/
.table-header-rotated {
  border-collapse: collapse;
  border-bottom: 1px solid #ccc;
}

.table-header-rotated th {
  border-top: none;
}

.table-header-rotated th.rotate {
  height: 100px;
  white-space: nowrap;
}

.table-header-rotated th.rotate > div {
  transform: translate(25px, -4px) rotate(325deg);
  width: 30px;
}

.table-header-rotated th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 3px 3px;
}

.table-header-rotated td {
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.table-header-rotated th.row-header {
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
}

/************************************* Toggle Buttons ***************/
.btn-group-toggle label.btn.active {
  color: #fff !important;
  background-color: #1985ac !important;
  border-color: #187da0 !important;
}

/*# sourceMappingURL=style.css.map */