/************************************* Main ***************/
.main .container-fluid {
  padding: 0 15px;
}

/************************************* Cards ***************/
.card .card-header {
  padding: .5rem;
  font-weight: bold;
}


/************************************* Tables ***************/
.overflow-row {
  overflow-x: auto;
}

/************************************* Select2 ***************/
.select2-no-borders {
  border: none !important;
}

.select2-no-background {
  background-color: inherit !important;
}

.select2-no-search .select2-search {
  display:none
}

/************************************* Modals ***************/
.modal-footer .btn {
  width: 100%;
}

@media (max-width: 576px) {
  .modal-footer {
    display: block;
  }

  .modal-footer .btn {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.modal-body {
  overflow-y: auto;
}

/************************************** Image Galleries *********/
.pswp__img {
  height: auto !important;
}

#thumbnails figure > a > img {
  max-width: 290px;
  max-height: 250px;
}

.thumbnail-icon {
  font-size: 85px;
  margin-bottom: 10px;
}

.thumbnail-icon-link {
  height: 250px;
  width: 250px;
}

@media (max-width: 1366px) {
  #thumbnails figure > a > img {
    max-width: 250px;
    max-height: 175px;
  }

  .thumbnail-icon {
    font-size: 60px;
    margin-bottom: 7px;
  }

  .thumbnail-icon-link {
    height: 175px;
    width: 175px;
  }
}
@media (max-width: 450px) {
  #thumbnails figure > a > img {
    max-width: 200px;
    max-height: 100px;
  }

  .thumbnail-icon {
    font-size: 35px;
    margin-bottom: 7px;
  }

  .thumbnail-icon-link {
    height: 100px;
    width: 100px;
  }
}

.modal-mode {
  #thumbnails figure > a > img {
    max-width: 100%;
    width: 100%;
    max-height: inherit;
  }

  .card-body {
    max-height: inherit;
  }

  figure {
    width: 100%
  }
}


  /************************************* Floating Labels ***************/
  // optional styles
  $label-color: #909090;
  $label-color-active: #0289f3;
  $label-color-inactive: rgba(#909090, 0.6);
  $input-border-color: rgba(#000000, 0.2);
  $input-border-color-active: #0289f3;
  $input-height: 1.875rem;

  // necessary styles
  .ffl-wrapper {
    @include floating-form-labels(1.25rem, 1rem);

    input,
    textarea {
      appearance: none;
    }
  }

  .dataTable th > input {
    appearance: none;
    transition: border-bottom 100ms ease;
    display: block;
    width: 100%;
    padding: 0;
    box: {
      shadow: none;
    }
    outline: none;
    background: {
      color: transparent;
    }
    border: {
      style: none;
      radius: 0;
      bottom: {
        width: 1px;
        style: solid;
        color: $input-border-color;
      }
    }
    height: $input-height;
    &:hover {
      border: {
        bottom: {
          width: 1px;
          color: $label-color;
        }
      }
    }
    &:focus {
      border: {
        bottom: {
          width: 1px;
          color: $input-border-color-active;
        }
      }
    }
  }

  .dataTable th > input {
    appearance: none;
    transition: border-bottom 100ms ease;
    display: block;
    width: 100%;
    padding: 0;
    box: {
      shadow: none;
    }
    outline: none;
    background: {
      color: transparent;
    }
    border: {
      style: none;
      radius: 0;
      bottom: {
        width: 1px;
        style: solid;
        color: $input-border-color;
      }
    }
    height: $input-height;
    &:hover {
      border: {
        bottom: {
          width: 1px;
          color: $label-color;
        }
      }
    }
    &:focus {
      border: {
        bottom: {
          width: 1px;
          color: $input-border-color-active;
        }
      }
    }
  }

  // necessary styles
  .ffl-wrapper {
    @include floating-form-labels(1.25rem, 1rem);

    input,
    textarea {
      appearance: none;
      transition: border-bottom 100ms ease;
      display: block;
      width: 100%;
      padding: 0;
      box: {
        shadow: none;
      }
      outline: none;
      background: {
        color: transparent;
      }
      border: {
        style: none;
        radius: 0;
        bottom: {
          width: 1px;
          style: solid;
          color: $input-border-color;
        }
      }
      min-height: $input-height;
      &:hover {
        border: {
          bottom: {
            width: 1px;
            color: $label-color;
          }
        }
      }
      &:focus {
        border: {
          bottom: {
            width: 1px;
            color: $input-border-color-active;
          }
        }
      }
    }

    input,
    textarea,
    select,
    .select2 .selection .select2-selection {
      transition: border-bottom 100ms ease;
      display: block;
      width: 100%;
      padding: 0;
      box: {
        shadow: none;
      }
      outline: none;
      background: {
        color: transparent;
      }
      border: {
        style: none;
        radius: 0;
        bottom: {
          width: 1px;
          style: solid;
          color: $input-border-color;
        }
      }
      min-height: $input-height;
      &:hover {
        border: {
          bottom: {
            width: 1px;
            color: $label-color;
          }
        }
      }
      &:focus {
        border: {
          bottom: {
            width: 1px;
            color: $input-border-color-active;
          }
        }
      }
    }

    .select2-hidden-accessible {
      display: none;
    }
  }


  .ffl-label {
    color: $label-color;
    line: {
      height: 1.2;
    }
    .ffl-floated & {
      color: $label-color-active;
      font: {
        size: 0.75rem;
      }
      overflow: visible;
    }
  }

  //Override for select2
  .select2 .selection .select2-selection .select2-selection__rendered {
    padding-left: 0;
  }

  textarea {
    resize: none;
    min: {
      height: $input-height;
    }
  }

  //Override for summernote
  .note-editor.note-frame .note-editing-area .note-editable b { font-weight: bold !important; }


  /************************************* Business Rules Highlighting ***************/
  .green-highlight {
    color: #006100 !important;
    background-color: #C6EFCE !important;
  }

  .yellow-highlight {
    color: #9C6500 !important;
    background-color: #FFEB9C !important;
  }

  .red-highlight {
    color: #9C0006 !important;
    background-color: #FFC7CE !important;
  }


  /************************************* Main Search Container ***************/
  #main-search-container  {
    background-color: white;
  }

  #main-search-container input {
    background-color: white;
    padding: 0 4px;
    margin-top: 0;
  }


  /************************************* Rotated Table Headers***************/
  .table-header-rotated {
    border-collapse: collapse;
    border-bottom: 1px solid #ccc;
    th {
      border-top: none;
    }
    th.rotate {
      height: 100px;
      white-space: nowrap;
      // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate
      > div {
        transform:
          // Magic Numbers
          translate(25px, -4px)
            // 45 is really 360-45
          rotate(325deg);
        width: 30px;
      }
      > div > span {
        border-bottom: 1px solid #ccc;
        padding: 3px 3px;
      }
    }

    td {
      border-right: 1px solid lightgrey;
      border-left: 1px solid lightgrey;
    }
    th.row-header {
      padding: 0 10px;
      border-bottom: 1px solid #ccc;
    }
  }


  /************************************* Toggle Buttons ***************/
  .btn-group-toggle label.btn.active {
    color: #fff !important;
    background-color: #1985ac !important;
    border-color: #187da0 !important;
  }
